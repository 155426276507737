import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import logo from '../images/logo.png';
import logo65 from '../images/logo65.png';
import logo110 from '../images/logo110.png';
import logo150 from '../images/logo150.png';
import FB from '../images/icon-facebook-64.png';
import IG from '../images/icon-instagram-64.png';
import TW from '../images/icon-twitter-64.png';

class Header extends Component {
    constructor(){
      super();
      this.state={
          mobilemenu: false,
      };
      this.hideComponent = this.hideComponent.bind(this);
  }
  
  hideComponent(varname){
    switch (varname)
    {
        case "mobilemenu":
            this.setState({mobilemenu: !this.state.mobilemenu});
            break;
        default: return;
    }
  }
  
      render() {
        const {mobilemenu} = this.state;
  
        return (
          <header>
            <div className="header">
              <div className="row headerTopBar">
                <div className="headerTopBar-Left">Mudeford Quay Cleanup Crew</div>
                <div className="headerTopBar-Right">
                  <nav aria-label="Social media links">
                    <ul>
                      <li className="navsocial"><a href="https://www.facebook.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Facebook (New Window)"><img src={FB} alt="Facebook" width="32px" height="32px" /></a></li>
                      <li className="navsocial"><a href="https://www.instagram.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Instagram (New Window)"><img src={IG} alt="Instagram" width="32px" height="32px" /></a></li>
                      <li className="navsocial"><a href="https://www.twitter.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Twitter (New Window)"><img src={TW} alt="Twitter" width="32px" height="32px" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="headerLeft">
                  <div className="headerNav">
                    <nav id="siteMenu" aria-label="Main site navigation">
                      <ul aria-label="Main site navigation">
                        <li id="home"><Link to='/'>Home</Link></li>
                        <li id="team"><Link to='/dates'>Dates</Link></li>
                        <li id="info"><Link to='/information' title="Questions and Information">FAQs</Link></li>
                        <li id="beach"><Link to='/team'>Team</Link></li>
                        <li id="stats"><Link to='/stats'>Stats</Link></li>
                      </ul>
                    </nav>
                    {!mobilemenu && <div id="menuClick" onClick={() => this.hideComponent("mobilemenu")}><span id="siteMenuMobile"></span></div>}
                    {mobilemenu && <div id="menuClick" onClick={() => this.hideComponent("mobilemenu")}><span id="siteMenuMobile" className="active" onClick={() => this.hideComponent("mobilemenu")}></span></div>}
                  </div>
                </div>
                <div className="headerMid">
                  <div className="headerLogo">
                    <a href="/" title="Home">
                      <img 
                        src={logo} alt="Mudeford Quay Cleanup Crew logo" width="200px" height="200px"
                        srcSet={`
                        ${logo65} 320w,
                        ${logo110} 568w,
                          ${logo150} 768w,
                          ${logo} 1024w,
                          ${logo} 1280w,
                          ${logo} 1366w,
                          ${logo} 1680w,
                          ${logo} 1920w
                        `}
                      />
                      <h1 className="visuallyhidden">Mudeford Quay Cleanup Crew</h1>
                    </a>
                  </div>
                </div>
                <div className="headerRight">
                  <button id="btnContact" className="buttonPrimary"><Link to='/contact'>Get in touch</Link></button>
                  <button id="btnShout" className="buttonSecondary"><Link to='/shout'>Litter Shout</Link></button>
                </div>
              </div>
            </div>
            {mobilemenu && 
              <nav id="mobileMenu">
                <ul>
                  <li id="home"><Link to='/'>Home</Link></li>
                  <li id="team"><Link to='/team'>Dates</Link></li>
                  <li id="info"><Link to='/information' title="Questions and information">FAQs</Link></li>
                  <li id="beach"><Link to='/team'>Team</Link></li>
                  <li id="stats"><Link to='/stats'>Stats</Link></li>
                  <li id="stats" className="buttonPrimary-Mobile"><Link to='/contact'>Get in touch</Link></li>
                  <li id="stats" className="buttonSecondary-Mobile"><Link to='/shout'>Litter Shout</Link></li>
                </ul>
              </nav>
            }
          </header>
        );
      }
    }
  export default Header;