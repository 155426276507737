import React, { Component } from 'react';
import BannerImage from '../images/contactbanner.jpg';
import BannerImage320 from '../images/contactbanner320.jpg';
import BannerImage568 from '../images/contactbanner568.jpg';
import BannerImage768 from '../images/contactbanner768.jpg';
import BannerImage1024 from '../images/contactbanner1024.jpg';
import BannerImage1280 from '../images/contactbanner1280.jpg';
import BannerImage1366 from '../images/contactbanner1366.jpg';
import BannerImage1680 from '../images/contactbanner1680.jpg';
import BannerImage1920 from '../images/contactbanner1920.jpg';
 
class ContactBanner extends Component {
  render() {
    return (
        <section>
            <div className="bannerImage contactBannerImage">
                <img 
                  src={BannerImage} alt="Seagull standing on a boat with its head tilted" width="1536px" height="747px"
                  srcSet={`
                  ${BannerImage320} 320w,
                  ${BannerImage568} 568w,
                    ${BannerImage768} 768w,
                    ${BannerImage1024} 1024w,
                    ${BannerImage1280} 1280w,
                    ${BannerImage1366} 1366w,
                    ${BannerImage1680} 1680w,
                    ${BannerImage1920} 1920w
                  `}
                  sizes="100vw"
                />
            </div>
        </section>
    );
  }
}
 
export default ContactBanner;