import React from 'react';

class Cookies extends React.Component {
    render(){
        return (
            <div className = "cookiesContent">
                <div className="pageTitle"><h2>Cookie Notice</h2></div>
                <div className="pageContent cookiePageContent">
                    <p>This site does not use any cookies which is why there is no cookie banner.</p>
                </div>
            </div>
        )
    }
}

export default Cookies;