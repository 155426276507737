import React from 'react';
import Banner from '../constants/banner';
import Crab from '../images/crab.png';

class Home extends React.Component {
    render(){
        return (
            <div className = "homeContent">
                <section className='banner' title="Banner image">
                    <div className="bannerOverlay"></div>
                    <Banner />
                </section>
                <section title="Main page content">
                    <div className="pageTitle"><h2>Welcome to the Crew!</h2></div>
                    <div className="pageContent">
                        <p>We are just starting out, so this site might look a little bit empty for now, but it will grow quickly with us.</p>
                        <p>We are very excited! We will be running regular litter picks throughout the year at Mudeford Quay and want this to grow into a real community, where crew members are actively looking after the area and contributing to our social channels.</p>
                        <p>We know many people pick up litter when they are out walking, but it's more fun together!</p>
                        <p>Send us details of what you're finding, post photos to our social media pages, or to yours and tag us so we can see what's going on and what amazing work you're doing.</p>
                        <p>If you spot a problem and can't deal with it at the time, post a “Litter Shout” and we will post a shout out to the crew on social media so someone can investigate.</p>
                        <p>Have a look at the news page for more on our vision!</p>
                        <br/>
                        <p className="highlight">Come and join the crew, we look forward to seeing you out there soon.</p>
                        <br/>
                        <p className="highlightImage"><img src={Crab} alt="Clip art crab" width="100px" height="100px" className="crab" /></p>
                    </div>
                </section>
            </div>
        )
    }
}

export default Home;