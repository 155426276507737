import React from 'react';
import emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useForm } from 'react-hook-form';
import ShoutBanner from '../constants/shoutbanner';

const Shout = () => {
    init("user_TQnYnnh8jtSM6yMmLSyYV");

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const toastifySuccess = () => {
        toast('Thank you, your message has been sent successfully!', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,  
          draggable: false,
          className: 'submit-feedback success',
          toastId: 'notifyToast'
        });
      };

    const onSubmit = async (data) => {
        const { problem, where, email } = data;

        try{
            const templateParams ={
                problem,
                where,
                email
            };

            await emailjs.send(
                "service_7ieyv8o",
                "template_mz2clia",
                templateParams,
                "user_TQnYnnh8jtSM6yMmLSyYV"
            );
            reset();
            toastifySuccess();
        } catch(e) {
            console.log(e);
        }
        
    }

    return (
        <div className="mainContent">
            <div className="pageTitle"><h2>Report a litter problem</h2></div>
            <ShoutBanner />
            <div className="pageContent">
                <div className="textContent">
                    <p>Have you spotted a litter hot-spot, something that needs removing or an ongoing problem with a specific type of litter?</p>
                    <p>We understand that it's not always possible to pick up litter when you're out and about, so if you've spotted a problem let us know and we'll put a shout out to the crew for someone to investigate.</p>
                </div>

                <div id="contactForm">
                    <form id='contact-form' noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className='row formRow'>
                            <label htmlFor="problem">What is the problem? <span className="required">(required)</span></label>
                            <input aria-label="What is the problem" type='text' name='problem' className='form-control formInput' placeholder='What is the problem' {...register('problem', {required: {value: true, message: 'Please enter your name'},maxLength: {valu: 200, message: 'Please use 200 characters or less'}})}></input>
                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        </div>
                        <div className='row formRow'>
                            <label htmlFor="problem">Where is the problem? <span className="required">(required)</span></label>
                            <input aria-label="Where is the problem" type='text' name='problem' className='form-control formInput' placeholder='Where is the problem' {...register('where', {required: {value: true, message: 'Please enter your name'},maxLength: {valu: 200, message: 'Please use 200 characters or less'}})}></input>
                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        </div>
                        <div className='row formRow'>
                        <label htmlFor="where">If you would like us to reply to you, please add your email address</label>
                            <input
                                aria-label="Enter your email address"
                                type='email'
                                name='email'
                                {...register('email', {
                                    required: false,
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                })}
                                className='form-control formInput'
                                placeholder='Email address'
                            ></input>
                            {errors.email && (
                                <span className='errorMessage'>Please enter a valid email address</span>
                            )}

                        </div>
                        <button className='submit-btn submit-btn-shout' type='submit'>
                            Shout!
                        </button>
                    </form>
                    <ToastContainer />
                </div>
            </div>
        </div>
    )
}

export default Shout;
