import React from 'react';
import letter24 from '../documents/letterofagreement2024.pdf';
import risk24 from '../documents/riskassessment24.pdf';
import insure from '../documents/insurance2024.pdf'

class Info extends React.Component {
    constructor(){
        super();
        this.state={
            section1: false,
            section2: false,
            section3: false,
            section4: false,
            section5: false,
            section6: false,
            section7: false,
            section8: false,
            section9: false,
            section10: false,
            section11: false,
            section12: false,
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(varname){
        switch (varname)
        {
            case "section1":
                this.setState({section1: !this.state.section1});
                break;
            case "section2":
                this.setState({section2: !this.state.section2});
                break;
            case "section3":
                this.setState({section3: !this.state.section3});
                break;
            case "section4":
                this.setState({section4: !this.state.section4});
                break;
            case "section5":
                this.setState({section5: !this.state.section5});
                break;
            case "section6":
                this.setState({section6: !this.state.section6});
                break;
            case "section7":
                this.setState({section7: !this.state.section7});
                break;
            case "section8":
                this.setState({section8: !this.state.section8});
                break;
            case "section9":
                this.setState({section9: !this.state.section9});
                break;
            case "section10":
                this.setState({section10: !this.state.section10});
                break;
            case "section11":
                this.setState({section11: !this.state.section11});
                break;
            case "section12":
                this.setState({section12: !this.state.section12});
                break;
            default: return;
        }
    }

    render(){
        const {section1, section2, section3, section4, section5, section6, section7, section8, section9, section10, section11, section12} = this.state;

        return (
            <div className="infoContent">
                <div className="pageTitle"><h2>Information and FAQs</h2></div>
                <div className="pageContent">
                    <div className="row rowInfo">
                    <div className="toggleSection">
                        <div className="toggleTitle toggleTitleWarn visuallyhidden" onClick={() => this.hideComponent("section1")}><h3 className="toggleHeading">Important: Heatwaves and Warm Weather</h3>{!section1 && <span className="toggleDown">+</span>}{section1 && <span className="toggleUp">-</span>}</div>
                        {section1 && 
                            <div className="toggleContent">
                                <p>Our summer events can take place during heatwave conditions and under warm weather alerts.</p>
                                <p>As nice as it would be for us to provide free sun cream and water for everyone, we do ask that all attendees take responsibility for themselves by ensuring that they are prepared for the conditions (and we know you do!).</p>
                                <p>You may wish to consider the following:</p> <br/>
                                <ul className="arList">
                                    <li>Wearing / bringing sun cream</li>
                                    <li>Wearing appropriate clothing to keep you protected and cool</li>
                                    <li>Wearing a hat</li>
                                    <li>Bringing water or another drink - avoid caffeine and alcohol</li>
                                    <li>Eat cold foods and fruit with high water content</li>
                                </ul>
                                <br/>
                                <p>There isn't a lot of shade at the Quay or on the beaches, so if you struggle with the heat it may be best to stay away from the summer events. Shade can be found by the play park or under the trees in the car park if you need to shelter.</p>
                                
                                <p>We always have a first aid kit on site and a flask of clean water for cleaning small wounds. Hand gel is always available.</p>
                                
                                <p>Please read this advice from the NHS on <a href="https://www.nhs.uk/live-well/seasonal-health/heatwave-how-to-cope-in-hot-weather/" target="_blank" rel="noopener noreferrer">How to cope in hot weather</a></p>
                                
                                <p>This link from the NHS advises on <a href="https://www.nhs.uk/conditions/heat-exhaustion-heatstroke/" target="_blank" rel="noopener noreferrer">Heat exhaustion and heatstroke</a>  </p>
                                
                                <p>If the heat is excessive, sessions may be cut short, so please check social media before attending.</p>                                
                                <br/>
                                <p>Thank you!</p>
                                <hr className="warnHR" />
                            </div>
                        }
                    </div>
                    <br/>
                    <p></p>
                    </div>
                    <p>We know, no one likes reading lots of text, so we've tried to make this as painless as possible!!</p>
                    <p>We are supported by BCP Council.</p>
                    <p>We don't gather at the beginning of events to do a big talk, we prefer that people can come along whenever they like during the two hour period. We are always happy to answer questions and provide advice at events, but we have answered some frequently asked questions below for you.</p>

                    <div className="row rowInfo">
                        <div className="colLeft">
                        <div className="textContent toggleContent">
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section1")}><h3 className="toggleHeading">Can anyone join?</h3>{!section1 && <span className="toggleDown">+</span>}{section1 && <span className="toggleUp">-</span>}</div>
                        {section1 && 
                            <div className="toggleContent">
                                Yes! Everyone is welcome. Come along to any event and get involved.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section2")}><h3 className="toggleHeading">Is this child friendly?</h3>{!section2 && <span className="toggleDown">+</span>}{section2 && <span className="toggleUp">-</span>}</div>
                        {section2 && 
                            <div className="toggleContent">
                                Definitely, please do bring children with you.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section3")}><h3 className="toggleHeading">Can I bring my dog?</h3>{!section3 && <span className="toggleDown">+</span>}{section3 && <span className="toggleUp">-</span>}</div>
                        {section3 && 
                            <div className="toggleContent">Yes you can, dogs are allowed at Mudeford Quay all year round. We do kindly ask that you keep them away from the kit when you're signing in if possible – we have had occasions where dogs have peed against the kit bags and that's not pleasant for anyone.</div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section4")}><h3 className="toggleHeading">Where can I park?</h3>{!section4 && <span className="toggleDown">+</span>}{section4 && <span className="toggleUp">-</span>}</div>
                        {section4 && 
                            <div className="toggleContent">There is parking at Mudeford Quay, you will have to pay for parking, please make sure you put enough on the ticket to cover the whole event!</div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section5")}><h3 className="toggleHeading">What happens when I arrive?</h3>{!section5 && <span className="toggleDown">+</span>}{section5 && <span className="toggleUp">-</span>}</div>
                        {section5 && 
                            <div className="toggleContent">
                                Come and introduce yourself, we will sign you in or ask you to register, issue you with kit and send you on your way!
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section6")}><h3 className="toggleHeading">What about Covid-19?</h3>{!section6 && <span className="toggleDown">+</span>}{section6 && <span className="toggleUp">-</span>}</div>
                        {section6 && 
                            <div className="toggleContent">
                                As this is an outdoor event, it is a low risk environment. We will keep our distance as far as possible. We will be monitoring the Government guidance and will restrict numbers if required.
                                <br/><br/>
                                It can get busy if everyone turns up at once, there is plenty of space so please spread out and come forward to check in when there is space! We will get through everyone as quickly as we possibly can.
                                <br/><br/>
                                We will have cleansing sprays available to wipe down equipment and we always have hand sanitiser available if you would like to use it.
                                <br/><br/>
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section7")}><h3 className="toggleHeading">Health and Safety Information</h3>{!section7 && <span className="toggleDown">+</span>}{section7 && <span className="toggleUp">-</span>}</div>
                        {section7 && 
                            <div className="toggleContent">
                                Every group has it's own version of the pre-event health and safety talk - essentially it is common sense.
                                <br/><br/>
                                At Mudeford Quay you shouldn't be able to get into too much trouble, but please be mindful of traffic when crossing the car parks. If you are covering Gundimore beach, please don't climb over rock groynes.
                                <br/><br/>
                                Don't pick up anything that you do not feel comfortable removing. If something is a cause for concern, please report back to the team and someone will take the appropriate action.
                                <br/><br/>
                                We always have sharps boxes on site, so if you find needles at a location please let us know and we will dispose of them.
                                <br/><br/>
                                Don't feel obliged to pick up dog poo, bagged or otherwise (or human poo, yes, it has happened!)
                                <br/><br/>
                                We have a first aid kit available so please ask for help if you need it.
                                <br/><br/>
                                During all events, we will be in a fixed place on the green and not cleaning along with you. This is so you can always find us for help and advice if required and it helps us to keep eyes on volunteers!
                                <br/><br/>
                            </div>
                        }           
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section8")}><h3 className="toggleHeading">What happens to the litter?</h3>{!section8 && <span className="toggleDown">+</span>}{section8 && <span className="toggleUp">-</span>}</div>
                        {section8 && 
                            <div className="toggleContent">
                                At these events there is too much returned for us to sort through, so it is consolidated into as few bags as possible and left by the bins ready for collection by the relevant waste management teams.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section9")}><h3 className="toggleHeading">Can I bring my paddle board / kayak?</h3>{!section9 && <span className="toggleDown">+</span>}{section9 && <span className="toggleUp">-</span>}</div>
                        {section9 && 
                            <div className="toggleContent">
                                This is definitely something that we want to do, we are just finalising the details to make sure that everyone is safe at all times. Please bear with us, we will let you know as soon as we can go ahead with water based volunteers.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section10")}><h3 className="toggleHeading">What is a "Litter Shout"?</h3>{!section10 && <span className="toggleDown">+</span>}{section10 && <span className="toggleUp">-</span>}</div>
                        {section10 && 
                            <div className="toggleContent">
                                We know that it's not always possible or desirable to pick up litter when you're out and about and spot it. A litter shout allows you to send the details to the crew and we will put out a shout on social media so whoever is nearby or available can go out and investigate.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section11")}><h3 className="toggleHeading">If I join, do I have to get involved with shouts or day to day cleaning?</h3>{!section11 && <span className="toggleDown">+</span>}{section11 && <span className="toggleUp">-</span>}</div>
                        {section11 && 
                            <div className="toggleContent">
                               No, absolutely not. Your level of involvement is entirely up to you. You don't have to attend every clean, you don't have to respond to any shout outs, there is absolutely no obligation to do anything at all! We are grateful for whatever you can do to help the crew.
                            </div>
                        }
                    </div>
                    <div className="toggleSection">
                        <div className="toggleTitle" onClick={() => this.hideComponent("section12")}><h3 className="toggleHeading">Event Safety</h3>{!section12 && <span className="toggleDown">+</span>}{section12 && <span className="toggleUp">-</span>}</div>
                        {section12 && 
                            <div className="toggleContent">
                                Dorset Police event safety advice - <a href="https://www.dorset.police.uk/eventsafety" target="_blank" rel="noopener noreferrer">https://www.dorset.police.uk/eventsafety</a>
                            </div>
                        }
                    </div>
                </div>

                        </div>
                        <div className="colRight">
                            <h3>How to find us</h3>
                            <h4>Address:</h4>
                            <p>Chichester Way, BH23 4AB</p>
                            <br/>
                            <p>We meet by the bridge that crosses from the pavement onto the Quay green, at the entrance to the car park.</p>
                            <br/>
                            <br/>
                            <h4>What 3 Words:</h4>
                            <p>taken.rope.united</p>
                            <br/>
                            <br/>
                            <h4>Car park information:</h4>
                            <p><a href="https://www.bcpcouncil.gov.uk/Parking/Find-a-car-park/car-park-single.aspx?carpark=Mudeford%20Quay%20car%20park" target="_blank" rel="noreferrer noopener">Mudeford Quay car park</a></p>
                            <br/>
                            <br/>
                            <p>For more travel and location information please see the <a href="https://www.visit-dorset.com/listing/mudeford-quay/115303301/">Visit Dorset</a> page.</p>
                            <br/>
                            <br/>
                            <h3>Group Documents</h3>
                            <ul>
                                <li><a href={risk24} target="_blank" rel="noopener noreferrer" title="Open the risk assessment in a new window">Risk Assessment</a></li>
                                <li><a href={letter24} target="_blank" rel="noopener noreferrer" title="Open the letter of agreement in a new window">Letter of Agreement 23/24</a></li>
                                <li><a href={insure} target="_blank" rel="noopener noreferrer" title="Open the Insurance document in a new window">Insurance</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Info;