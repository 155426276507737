import React from 'react';
import Team1 from '../images/team1.jpg'
import Team2 from '../images/team2.png'

class Team extends React.Component {
    render(){
        return (
            <div className = "teamContent">
                <div className="pageTitle"><h2>Crew Captains</h2></div>
                <div className="pageContent">
                    <p>Here's a quick introduction to the people managing things behind the scenes here at crew HQ. They will be on site at all events and are always happy to chat and answer any questions you may have.</p>
                    <div className="row">
                        <div className="colHalf">
                            <div className="teamMember">
                                <div className="teamImage"><img src={Team1} alt="Close up of Amelia!" width="527px" height="527px"/></div>
                                <div className="teamName">Amelia</div>
                                <div className="teamText">It's entirely possible that you've seen Amelia popping up around Mudeford and Highcliffe running various beach cleans. She's expanding her mission to keep the area clean with this new group dedicated to the Quay. Amelia will be running our socials and leading on site at events.</div>
                            </div>
                        </div>
                        <div className="colHalf">
                            <div className="teamMember">
                                <div className="teamImage"><img src={Team2} alt="Placeholder graphic of a person" width="527px" height="527px" /></div>
                                <div className="teamName">Is this you?</div>
                                <div className="teamText">Amelia is looking for a team mate! Before we can expand the group to include water-based clean ups, we need a 2nd. This person either needs to have a First Aid qualification or be willing to take a course (this can be funded). Please get in touch!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team;