import React from 'react';
import QR from '../images/ar-qr.png';

class Play extends React.Component {
    render(){
        return (
            <div className = "playContent">
                <div className="pageTitle"><h2>Welcome to the Crew's Augmented Reality!</h2></div>
                
                <div className="pageContent">
                    <br/>
                    <p>We have created this little app just for fun. You don't need to download anything or sign up anywhere, all you need is an internet connection.</p>
                    <p>It will ask for these permissions:</p>
                    <ul className="arList">
                        <li>Camera</li>
                        <li>Location</li>
                    </ul>
                    <br/>
                    <p>These are needed to locate your device and show you the virtual items that have been placed around the Quay.</p>
                    <p>This data is not tracked or stored in any way and is not used for any purpose other than that stated above.</p>
                    <br/>
                    <hr/>
                    <br/>
                    We will have the QR code printed at our litter picks so you can scan it to get started, but if you want to preload it, scan here!<br/><br/>
                    <img src={QR} alt="QR Code to access the AR app" className="qrCode" width="400px" height="400px" />
                    <br/><br/>
                    <hr/>
                    <p>The items that you can find are:</p>
                    <ul className="arList">
                        <li>A packet of crisps - Crabbies Super Salted</li>
                        <li>A drink bottle - Crabbies Cordial</li>
                        <li>A fork</li>
                        <li>A straw</li>
                        <li>A sauce sachet - Crabbies Ketchup</li>
                    </ul>
                    <br/>
                    <p>As a bonus, see if you can track down Crabbie too!</p>
                    <br/>
                    <hr/>
                    <br/>
                    <p>Single-use plastic items stay in our environment for a very long time. The app will give you the stats as you find them, but here are the sources for that information.</p>
                    <br/>
                    <p>
                        <strong>Crisp Packets</strong>
                        <br/>
                        Crisp packets can take up to 80 years to decompose. Packets from the 1960s are still found on litter picks and beach cleans today.  
                        <br/>
                        We don't have a figure specifically for crisp packets locally, but there were at least 1,333 pieces of food packaging collected from local beaches in 2022.
                        <br/>
                        Source link: <a href="https://www.ibtimes.co.uk/this-how-many-years-it-takes-these-everyday-items-decompose-1650426" target="_blank" rel="noopener noreferrer">https://www.ibtimes.co.uk/this-how-many-years-it-takes-these-everyday-items-decompose-1650426</a>
                    </p>
                    <br/>
                    <p>
                        <strong>Plastic Bottles</strong>  
                        <br/>
                        Plastic bottles take around 450 years to break down! They are a commonly littered item and get washed up in all kinds of conditions. 
                        <br/>
                        In 2022 at least 194 plastic drink bottles were collected from local beaches.
                        <br/>
                        Source link: <a href="https://www.thegoodforco.com/how-long-does-it-take-for-a-plastic-bottle-to-break-down/" target="_blank" rel="noopener noreferrer">https://www.thegoodforco.com/how-long-does-it-take-for-a-plastic-bottle-to-break-down/</a>  
                    </p>
                    <br/>
                    <p>
                        <strong>Forks</strong>  
                        <br/>
                        Plastic cutlery takes up to 200 years to decompose. 
                        <br/>
                        In 2022 at least 45 items of plastic cutlery were picked up from local beaches. 
                        <br/>
                        Source link: <a href="https://www.watergen.com/blog/the-price-we-pay-for-convenience-the-true-impact-of-plastic-cutlery/" target="_blank" rel="noopener noreferrer">https://www.watergen.com/blog/the-price-we-pay-for-convenience-the-true-impact-of-plastic-cutlery/</a>
                    </p>
                    <br/>
                    <p>
                        <strong>Straws</strong>  
                        <br/>
                        Plastic straws can take anywhere from 100 to 200 years to decompose. 
                        <br/>
                        In 2022, at least 36 plastic straws were collected from local beaches.
                        <br/>
                        Source link: <a href="https://nationwideplastics.net/product/how-long-does-it-take-for-plastic-straws-to-decompose.html" target="_blank" rel="noopener noreferrer">https://nationwideplastics.net/product/how-long-does-it-take-for-plastic-straws-to-decompose.html</a>
                    </p>
                    <br/>
                    <p>
                        <strong>Sauce Sachets</strong>  
                        <br/>
                        Plastic sauce sachets can take 20 to 50 years to decompose. 
                        <br/>
                        In 2022 at least 132 plastic takeway sachets were collected from local beaches.
                        <br/>
                        Source link: <a href="https://havenhillcuisine.com/the-plastic-pollution-crisis-how-long-does-it-take-for-ketchup-packets-to-biodegrade/" target="_blank" rel="noopener noreferrer">https://havenhillcuisine.com/the-plastic-pollution-crisis-how-long-does-it-take-for-ketchup-packets-to-biodegrade/</a>
                    </p>
                    <p>
                        <strong>Crabs</strong>  
                        <br/>
                        Crabs live on average 2-5 years, but there are species that will live up to 70 years!
                        <br/>
                        Source link: <a href="https://www.reference.com/pets-animals/long-crabs-live-4e03d8a4c1741401" target="_blank" rel="noopener noreferrer">https://www.reference.com/pets-animals/long-crabs-live-4e03d8a4c1741401</a>
                    </p>
                    <br/>
                    <hr/>
                    <br/>
                    <p>Have fun!!!</p>
                </div>
            </div>
        )
    }
}

export default Play;