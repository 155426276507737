import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import FB from '../images/icon-facebook-64.png';
import IG from '../images/icon-instagram-64.png';
import TW from '../images/icon-twitter-64.png';

class Footer extends Component {
      render() {
 
        return (
          <footer>
            <div className="footer">
              <div className="footerLeft">
                Copyright &copy; <a href="https://www.ameliaraine.me.uk" target="_blank" rel="noopener noreferrer" className="ob" title="Open in new window">msrainea</a>
              </div>
              <div className="footerMid">
                <nav aria-label="Footer quick links">
                  <ul>
                    <li><Link to='/privacy'>Privacy Policy</Link></li>
                    <li><Link to='/cookies'>Cookies</Link></li>
                  </ul>
                </nav>
              </div>
              <div className="footerRight">
                <nav arial-label="Footer social media links">
                  <ul>
                    <li className="navsocial navsocialfooter"><a href="https://www.facebook.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Facebook (New Window)"><img src={FB} alt="Facebook" width="32px" height="32px" /></a></li>
                    <li className="navsocial navsocialfooter"><a href="https://www.instagram.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Instagram (New Window)"><img src={IG} alt="Instagram" width="32px" height="32px" /></a></li>
                    <li className="navsocial navsocialfooter"><a href="https://www.twitter.com/mudefordquaycc" target="_blank" rel="noopener noreferrer" title="Twitter (New Window)"><img src={TW} alt="Twitter" width="32px" height="32px" /></a></li>
                  </ul>
                </nav>
              </div>
            </div>
            
          </footer>
        );
      }
    }
  export default Footer;
  